import React, { useContext } from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { Box, Button, Container, Divider, Grid, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography, Paper } from '@mui/material';
import TypographyCustom from '../../Components/Typography/TypographyCustom';
import { FormattedMessage } from 'react-intl';
import { primaryColor, redColor, whiteColor } from '../../Utils/Constants';
import { DataContext } from '../../Context/DataContext';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getDetailTransactionCamp } from '../../Components/api/transactionCamp';
import { formatPrice } from '../../Utils/functionUtils';
import Swal from 'sweetalert2/dist/sweetalert2.all.js'
import withReactContent from 'sweetalert2-react-content'

const Order = () => {
  const { allState } = useContext(DataContext);
  const { orderForm } = allState;
  const [data, setData] = React.useState({});
  const { id } = useParams();
  const MySwal = withReactContent(Swal);

  const generateButtonChange = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={handleFeatureNotAvailable} size="small" variant="contained" style={{ backgroundColor: redColor, color: whiteColor, width: '100%', marginBlock: "1rem" }}>
          <FormattedMessage id="cancelOrder" />
        </Button>
        <Button onClick={handleFeatureNotAvailable} size="small" variant="contained" style={{ backgroundColor: whiteColor, color: primaryColor, width: '100%', marginBlock: "1rem", marginLeft: "1rem" }}>
          <FormattedMessage id='orderChangeOrderDetail' />
        </Button>
      </div>
    );
  }

  const handleFeatureNotAvailable = () => {
    MySwal.fire({
      title: <strong>Peringatan</strong>,
      html: <div> <p>Fitur ini belum tersedia</p> </div>,
      icon: 'warning'
    })
  }

  useEffect(() => {
    getDetailTransactionCamp(id)
      .then((res) => {
        setData(res.data.data);
      })
  })

  const generateStatus = (status) => {
    switch (status) {
      case 'PENDING':
        return <FormattedMessage id="orderWaitingPayment" />;
      case 'PAID':
        return <FormattedMessage id="orderPaid" />;
      case 'CANCELED':
        return <FormattedMessage id="orderCanceled" />;
      default:
        return <FormattedMessage id="orderWaitingPayment" />;
    }
  }

  return (
    <div>
      <NavBar />
      <Box sx={{ marginBlock: { xs: '0px', sm: '2rem' } }}>
        <Container style={{ textAlign: "start" }}>
          <Grid container spacing={5}>
            <Grid item xs={0} md={2}>
            </Grid>
            <Grid item xs={12} md={8}>
              <TypographyCustom
                variant="h6"
                content="orderMyOrder"
              />
              <Typography variant='body1'>
                <FormattedMessage id="orderNumber" />
                <span style={{ color: primaryColor }}>: {id}</span>
              </Typography>
              <Divider />
              <Typography variant='h6' style={{ color: primaryColor, fontWeight: "700", marginTop: "1rem" }}>
                <FormattedMessage id="orderStatus" />
                <span>: {generateStatus(data.status)}</span>
              </Typography>
              <Typography variant='h5'>
                Rp {formatPrice(data.price)}
              </Typography>
              <Typography variant='caption' style={{ color: primaryColor }}>
                <FormattedMessage id="orderTransferBefore" />
                <span> {moment(data.pickupDate).format('DD MMMM YYYY HH:mm')}</span>
              </Typography>
              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <div>
                  <Typography variant='h6' style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="accountBankName" />
                  </Typography>
                  <Typography variant='h5'>
                    6485242701
                  </Typography>
                </div>
                <img src={`/images/payment/bca.png`} alt={"bca"} width={90} />
              </div>
              <Divider style={{ marginBottom: "1rem" }} />
              <div>
                <TypographyCustom
                  variant="caption"
                  content="checkoutName"
                />
                <Typography variant='subtitle2'>
                  {data.name}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="checkoutEmail"
                />
                <Typography variant='subtitle2'>
                  {data.email}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="checkoutPhoneNumber"
                />
                <Typography variant='subtitle2'>
                  {data.phoneNumber}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="headerFormPickUp"
                />
                <Typography variant='subtitle2'>
                  {`${moment(data.pickupDate).format('DD MMMM YYYY')} ${moment(orderForm.pickUpTime.$d).format('HH:mm')}`}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="headerFormDropOff"
                />
                <Typography variant='subtitle2'>
                  {`${moment(data.returnDate).format('DD MMMM YYYY')} ${moment(orderForm.dropOffTime.$d).format('HH:mm')}`}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="headerFormPickUpLocation"
                />
                <Typography variant='subtitle2'>
                  {data.address}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="checkoutVehicle"
                />
                <TableContainer component={Paper}>
                  <Table style={{ textAlign: 'center' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Produk</TableCell>
                        <TableCell align="center">Kuantitas</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        data.detail && data.detail.map((item) => {
                          return (
                            <TableRow
                              key={item.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell>{item.id}</TableCell>
                              <TableCell align="center">{item.quantity}</TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
              </div>
              {generateButtonChange()}
              <Divider style={{ marginBottom: "1rem" }} />
            </Grid>
            <Grid item xs={0} md={2}>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </div>
  );
};

export default Order;