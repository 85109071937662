import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ToggleLanguage from '../Toggle/ToggleLanguage';
import { BASE_URL, base_url_images, blackColor, fontFamily, primaryColor, whiteColor } from '../../Utils/Constants';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const pages = ['navbarHome', 'navbarListProduct', 'navbarAboutUs', 'navbarBlog', 'navbarContactUs'];
const endPoint = [BASE_URL, '/all-items', `${BASE_URL}/about-us`, `${BASE_URL}/blog`, `${BASE_URL}/contact-us`]

const NavBar = (props) => {
  const { isLanding } = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (directions) => {
    setAnchorElNav(null);
    if (directions.includes('https')) {
      window.location.href = directions;
    } else if (directions) {
      window.location.href = "." + directions;
    }
  };

  const handleCurrentPage = (endPoint) => {
    if (endPoint.includes('https')) {
      window.location.href = endPoint;
    } else {
      navigate(endPoint);
    }
  }

  const handleLanding = () => {
    window.location.href = '/';
  }


  return (
    <AppBar position="static" elevation={isLanding ? 0 : 1} style={{ backgroundColor: "rgba(255, 255, 255, 0)", height: "10%" }}>
      <Container maxWidth>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <img alt='logo' sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} src={`${base_url_images}logo.png`} width={150} height={100} onClick={handleLanding} style={{ cursor: 'pointer' }} />
          </Box>

          <Box sx={{ flexGrow: -1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{ color: blackColor }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu(null)}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem key={page} onClick={() => handleCloseNavMenu(endPoint[index])}>
                  <Typography style={{ fontFamily: fontFamily, fontWeight: page === 'navbarListProduct' ? '0' : '600' }} textAlign="center"><FormattedMessage id={page} /></Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 6, display: { xs: 'none', md: 'flex' }, justifyContent: "center" }}>
            {pages.map((page, index) => (
              <Button
                key={page}
                onClick={() => handleCurrentPage(endPoint[index])}
                sx={{ my: 2, color: isLanding ? whiteColor : primaryColor, display: 'block', fontWeight: page === 'navbarListProduct' ? '0' : '600', fontFamily: fontFamily }}
              >
                <FormattedMessage id={page} />
              </Button>
            ))}
          </Box>
          <ToggleLanguage />

        </Toolbar>
      </Container>
    </AppBar >
  );
}
export default NavBar;