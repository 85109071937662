import { Typography } from '@mui/material';
import React from 'react';

const CopyRight = () => {
    return (
        <div style={{ height: "50px" }}>
            <div style={{ marginTop: '5%' }}>
                <Typography variant="body2" color="white" align="left">
                    © {new Date().getFullYear()} Bali Camp | Sewa Alat Camp di Bali
                </Typography>
            </div>
        </div>
    );
};

export default CopyRight;