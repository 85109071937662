import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import { Modal } from 'antd';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import { FormattedMessage } from 'react-intl';
import { formatPrice } from '../../Utils/functionUtils';
import { useState } from 'react';
import { DataContext } from '../../Context/DataContext';
import { useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.all.js'
import withReactContent from 'sweetalert2-react-content'

const ModalDetailCamp = (props) => {
    // Create swal object
    const MySwal = withReactContent(Swal);

    const { modalOpen, setModalOpen, image, priceDay, stock, id } = props;
    const { allState } = React.useContext(DataContext)
    const { rentDate, orderCamp, setOrderCamp, setShowAffixCart } = allState;
    const [quantity, setQuantity] = useState(1);
    useEffect(() => {
        if (orderCamp) {
            const order = orderCamp.find((item) => item.id === id);
            if (order) {
                setQuantity(order.quantity);
            }
        }
    }, [orderCamp])

    const handleMinusQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    }

    const handlePlusQuantity = () => {
        if (quantity < stock) {
            setQuantity(quantity + 1);
        }
    }
    const handleAddToCart = () => {
        setModalOpen(false);
        if (rentDate) {
            const cart = orderCamp.filter((item) => item.id !== id);
            cart.push({ id, quantity });
            localStorage.setItem('cartcamp', JSON.stringify(cart));
            setOrderCamp(cart);
            setShowAffixCart(true);
        } else {
            MySwal.fire({
                title: <strong>Peringatan</strong>,
                html: <div> <p>Anda belum memilih tanggal sewa</p> </div>,
                icon: 'warning'
            })
        }
    }

    return (
        <Modal
            // title="Vertically centered modal dialog"
            centered
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            width={500}
            footer={[
                <Button style={{ backgroundColor: whiteColor, color: primaryColor }} variant="contained" key="back" onClick={() => setModalOpen(false)}>
                    Kembali
                </Button>,
                <Button style={{ backgroundColor: primaryColor, color: whiteColor, marginLeft: '20px' }} variant="contained" key="submit" type="primary" onClick={handleAddToCart}>
                    <FormattedMessage id="order" />
                </Button>
            ]}
        >
            <Grid container>
                <Grid item xs={12} md={6}>
                    <img src={`images/camp/${image}`} alt="gambar" style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box style={{ marginTop: "20px" }}>
                        <Typography variant='h5' style={{ color: primaryColor }}>
                            Harga
                        </Typography>
                        <Typography variant='body1'>
                            {`${formatPrice(priceDay)} / hari`}
                        </Typography>
                    </Box>
                    <div>
                        <Typography variant='body2' style={{ color: primaryColor, marginTop: '15px' }}>
                            Stock {' '} {stock}
                        </Typography>
                    </div>
                    <br />
                    <div style={{ display: 'flex' }}>
                        <Typography variant='h5' style={{ color: primaryColor }}>
                            Jumlah
                        </Typography>
                        <div style={{ display: 'flex', marginLeft: '10px' }}>
                            <button onClick={handleMinusQuantity} style={{ backgroundColor: primaryColor, border: '0', color: whiteColor, width: '50%', cursor: 'pointer' }}>-</button>
                            <button style={{ border: '0', width: '50%' }}>{quantity}</button>
                            <button onClick={handlePlusQuantity} style={{ backgroundColor: primaryColor, border: '0', color: whiteColor, width: '50%', cursor: 'pointer' }}>+</button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ModalDetailCamp;