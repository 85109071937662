import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import CardItem from '../../Components/Card/CardItem/CardItem';
import { DatePicker, Input, Skeleton, Space } from 'antd';
import { SearchOutlined } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import { getAllCamp } from '../../Components/api/camp';
import { DataContext } from '../../Context/DataContext';
import dayjs from 'dayjs';

const styleContainer = {
  marginBottom: "40px"
};

const BodyListItem = (props) => {
  const { navigateToCheckout } = props;
  const intl = useIntl();
  const { allState } = React.useContext(DataContext)
  const { rentDate, setRentDate } = allState;
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  const [listCamp, setListCamp] = useState([]);
  const [listCampBackup, setListCampBackup] = useState([]);

  useEffect(() => {
    const filteredCamp = listCampBackup.filter((item) => {
      return item.name.toLowerCase().includes(keyword.toLowerCase())
    });
    setListCamp(filteredCamp);
  }, [keyword])

  useEffect(() => {
    if (rentDate) {
      setLoading(true);
      getAllCamp(rentDate[0], rentDate[1])
        .then((res) => {
          setListCampBackup(res.data.data);
          if (keyword) {
            const filteredCamp = res.data.data.filter((item) => {
              return item.name.toLowerCase().includes(keyword.toLowerCase())
            });
            setListCamp(filteredCamp);
          } else {
            setListCamp(res.data.data);
          }
        })
        .finally(() => {
          setLoading(false);
        })
    } else {
      getAllCamp()
        .then((res) => {
          setListCampBackup(res.data.data);
          if (keyword) {
            const filteredCamp = res.data.data.filter((item) => {
              return item.name.toLowerCase().includes(keyword.toLowerCase())
            });
            setListCamp(filteredCamp);
          } else {
            setListCamp(res.data.data);
          }
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }, [rentDate])

  useEffect(() => {
    const rentDate = JSON.parse(localStorage.getItem('rentDate'));
    if (rentDate) {
      setRentDate(rentDate);
    }
  }, [])

  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  }

  const handleChangeRentDate = (date, dateString) => {
    setRentDate(dateString);
    localStorage.setItem('rentDate', JSON.stringify(dateString));
  }

  return (
    <>
      <Container style={styleContainer} grid>
        <div style={{ marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'start' }}>
              <DatePicker.RangePicker
                disabledDate={(current) => {
                  return current && current < dayjs();
                }}
                value={rentDate ? [dayjs(rentDate[0], 'YYYY-MM-DD'), dayjs(rentDate[1], 'YYYY-MM-DD')] : null}
                style={{ width: '100%', marginBottom: "20px" }}
                placeholder={[intl.formatMessage({ id: 'hedaerFormPickUpPlaceHolder' }),
                intl.formatMessage({ id: 'headerFormDropOffPlaceHolder' })]}
                onChange={handleChangeRentDate}
              />
              <Space.Compact size="large" style={{ width: '100%', marginBottom: "30px" }} >
                <Input value={keyword} onChange={handleChangeKeyword} style={{ backgroundColor: primaryColor, borderRadius: "8px" }} addonBefore={<SearchOutlined style={{ color: whiteColor }} />} placeholder="Cari " />
              </Space.Compact>
              {
                listCamp.length === 0 || loading
                  ? <Grid container spacing={3}>
                    <Grid key={1} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCamp.length === 0 || loading} active /></Grid>
                    <Grid key={2} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCamp.length === 0 || loading} active /></Grid>
                    <Grid key={3} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCamp.length === 0 || loading} active /></Grid>
                    <Grid key={4} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCamp.length === 0 || loading} active /></Grid>
                    <Grid key={5} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCamp.length === 0 || loading} active /></Grid>
                    <Grid key={6} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCamp.length === 0 || loading} active /></Grid>
                  </Grid>
                  : null
              }
              <Grid container spacing={3}>
                {
                  !loading && listCamp.map((item, index) => {
                    return (
                      <Grid item xs={12} md={6} lg={3}>
                        <CardItem
                          id={item.id}
                          name={item.name}
                          priceDay={item.pricePerDay}
                          priceDayUp={item.pricePerDayUp}
                          image={item.image}
                          oldStock={item.oldStock}
                          stock={item.stock}
                          description={item.description}
                          navigateToCheckout={navigateToCheckout}
                          priceFont="body1"
                        />
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container >
    </>
  );
};

export default BodyListItem;