import React from "react";
import {
    BrowserRouter,
    Route,
    Routes,
    Navigate
} from "react-router-dom";
import ListItemRent from "./Pages/ListItemRent/ListItemRent";
import Cart from "./Pages/Cart/Cart";
import Order from "./Pages/Order/Order";

const RoutesPages = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ListItemRent />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/order/:id" element={<Order />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default RoutesPages;