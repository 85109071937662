const textEn = {
  // NAVBAR
  navbarHome: "Home",
  navbarSelectVehicle: "Choose Vehicle",
  navbarPriceList: "Price List",
  navbarHelp: "Help",
  navbarBlog: "Blog",
  navbarTransaction: "List Transaction",
  navbarOutcomeTransaction: 'List Outcome Transaction',
  navbarListVehicle: "List Vehicle",
  navbarListProduct: "List Product",
  navbarAboutUs: "About Us",
  navbarContactUs: "Contact Us",
  navbarCart: "Cart",

  //FOOTER
  footerUsefulLinks: "Useful Links",
  footerProduct: "Product",

  // HEADER
  welcomeHeaderPartOne: "Bali rent car starting from",
  welcomeHeaderPartTwo: "100",
  welcomeHeaderPartThree: "thousand only",
  bodyJargon: "Rent a vehicle anywhere and anytime easily",
  benefitHeaderOne: "Can be delivered anywhere",
  benefitHeaderTwo: "Free Cancellation",
  benefitHeaderThree: "100+ Cars and Motorcycles",
  benefitCaptionOne: "We can deliver the vehicle to your location. Free delivery fee for certain locations",
  benefitCaptionTwo: "Free cancellation of vehicle rental if you change your mind. No cancellation fee",
  benefitCaptionThree: "We have more than 100 vehicles that you can rent. Starting from cars to motorcycles",

  // HEADER FORM
  headerFormRentNow: "Book Now",
  headerFormPickUpLocation: "Pick Up Location",
  headerFormPickUpLocationPlaceholder: "Enter Location",
  headerFormPickUp: "Pick Up",
  hedaerFormPickUpPlaceHolder: "Start Rent",
  hedaerFormPickUpTimePlaceHolder: "Select Time",
  headerFormDropOff: "Drop Off",
  headerFormDropOffPlaceHolder: "End Rent",
  headerFormDropOffTimePlaceHolder: "Select Time",
  headerFormContinueBooking: "Continue Booking",
  headerFormSubmit: "Submit",
  seePriceList: "Price List →",

  // CHECKOUT
  checkoutForm: "Checkout",
  checkoutVehicle: "Vehicle",
  checkoutVoucher: "Voucher Code",
  checkoutVoucherPlaceholder: "Enter Voucher Code",
  checkoutTypeVehicle: "Type Vehicle",
  checkoutTypeMatic: "- Matic",
  checkoutTypeManual: "- Manual",
  checkoutTypeElectric: "- Electric",
  checkoutTermAndCondition: "I agree to the terms and conditions",
  checkoutReadTermAndCondition: "Read terms and conditions",
  checkoutPaymentDetail: "Payment Details",
  checkoutPaymentRentPrice: "Rent Price",
  checkoutPaymentDiscountVoucher: "Discount Voucher",
  checkoutPaymentAdminFee: "Admin Fee",
  checkoutPaymentTotal: "Total",
  checkoutName: "Your name",
  checkoutNamePlaceholder: "Enter your name",
  checkoutEmail: "Email",
  checkoutEmailPlaceholder: "Enter email",
  checkoutPhoneNumber: "Phone Number (WhatsApp)",
  checkoutPhoneNumberPlaceholder: "Enter Phone Number",
  checkoutPaymentMethod: "Payment Method",
  bri: "BRI",
  bca: "BCA",
  mandiri: "Mandiri",
  accountBankName: "a.n Kadek Dwi Bagus",
  or: "OR",

  // ORDER
  orderMyOrder: "My Order",
  orderNumber: "No. Order",
  orderStatus: "Status",
  orderTransferBefore: "Transfer Before",
  orderWaitingPayment: "Waiting Payment",
  orderPaymentSuccess: "Payment Success",
  orderName: "Name :",
  orderEmail: "Email :",
  orderPhoneNumber: "Phone Number :",
  orderVehicle: "Vehicle :",
  orderTypeVehicle: "Type :",
  orderPickUp: "Pick Up :",
  orderDropOff: "Drop Off :",
  orderLocation: "Location :",
  orderVoucher: "Voucher :",
  orderChangePaymentMethod: "Change Payment Method",
  orderChangeOrderDetail: "Change Order Detail",
  orderChangeVehicle: "Change Vehicle",
  cancelOrder: "Cancel Order",

  // BODY LANDING
  bodyBestDeals: "Best Deals",

  // FOOTER
  greeting: 'Hello, {name}!',
  instagram: 'Instagram',
  tiktok: 'Tiktok',
  whatsapp: 'Whatsapp',
  aboutUs: 'About Us',
  contactUs: 'Contact Us',
  help: 'Help',
  location: 'Location',
  socialMedia: 'Social Media',
  company: 'Company',

  // ITEM
  itemOrderVehicle: "Order",
  itemAddToCart: "Add To Cart",

  // LIST ITEM 
  listItemVehicle: "Vehicle",
  listItemVehicleAll: "All Vehicle",
  listItemVehicleItem1: "Motorcycle",
  listItemVehicleItem2: "Car",
  listItemTypeVehicle: "Vehicle Type",
  listItemTypeVehicleAll: "All Type",
  listItemTypeVehicleItem1: "Matic",
  listItemTypeVehicleItem2: "Manual",
  listItemTypeVehicleItem3: "Electric",

  // PRICE LIST
  priceListVehicle: "Vehicle",
  priceListName: "Name",
  priceListTypeVehicle: "Type",
  priceListPrice: "Price",
  priceListPricePerDay: "Price/day",
  priceListPricePerMonth: "Price/month",
  priceListDetail: "Detail",

  // HELP
  helpNeedHelp: "Need Help?",
  helpNeedHelpCaption: "We are here to help you 24/7. If you have any questions, please contact us.",
  helpName: "Name",
  helpNamePlaceholder: "Enter Name",
  helpEmail: "Email",
  helpEmailPlaceholder: "Enter Email",
  helpPhoneNumber: "Phone Number (WhatsApp)",
  helpPhoneNumberPlaceholder: "Enter Phone Number",
  helpMessage: "Message",
  helpMessagePlaceholder: "Enter Message",
  helpSend: "Send",

  // util
  validateNik: "Validate NIK",

  // TNC
  tncTitle: "Term and Condition",
  tnc1: "International driving license is required for foreigners, and Local Driving License for Indonesia",
  tnc2: "Rental rates are inclusive insurance of the vehicle. Accident damages of theft or car / vehicle, or an part of it is the costumers, own liability up to $300 (Three hundred US dollar).",
  tnc3: "Car rental prices are calculated for use per day (24 hours) with the car rental service, Self Drive Excess time of use of a car or car rental overtime will be charged an addtional fee of Rp. 30.000 per hour from the normal price of the relevant car rental. The maximum overtime is 2 hours. Overtime in Excess of 2 hours will be charged the full day car rental fee.",
  tnc4: "Renter is fully responsible for any damage, theft of the vehicle, or any part of it if the vehicle is driven not on the ordanary road (e.g beach, field, etc).",
  tnc5: "Rentel is fully responsible for any consequence caused by letting or giving the vehicle to be driven by person other than the renter.",
  tnc6: "The renter can not give the vehicle to be driven by any other person and it is not permitted to be drive the vehicle to outside the island without approval of the rental company",
  tnc7: "Above particular consideration the rental right to cancel the lease unilaterally even if agreement already signed.",
  tnc8: "US $ 20 / 50 / 100 / 200 will be charge if you lost the ignitions key.",
  tnc9: "US $ 5 / 80 will be charge if you lost the vehicle registration.",
  tnc10: "The rentet must be given foto copy passport and Driving License.",
  tnc11: "A photograph of the renter will be taken for identification purposes.",
  tnc12: "Cancellation fee is 50% (Fifty percent) of agreed charge and non refundable if you should change your mind.",

  // DUMMY TEXT
  dummyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae diam euismod, aliquam nisl ac, tincidunt sapien. Nul",
  dummyTnc: "1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae diam euismod, aliquam nisl."
};

export default textEn;
