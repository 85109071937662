import moment from "moment";

// change format price 
export const formatPrice = (price) => {
    try {
        if (price === undefined) return '0';
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } catch (error) {
        return error;
    }
}

// text wa order
export const textWaOrder = (lang, car, date) => {
    try {
        if (lang === 'en') {
            if (car && date) return `Hello My Bali Rent Car, I want to order a ${car} on ${moment(date).format('DD MMMM YYYY')}. Thank you.`;
            else if (car) return `Hello My Bali Rent Car, I want to order a ${car}. Thank you.`;
            else return `Hello My Bali Rent Car, I want to order a car/motorcycle. Thank you.`;
        } else {
            if (car && date) return `Halo My Bali Rent Car, saya ingin memesan ${car} pada tanggal ${moment(date).format('DD MMMM YYYY')}. Terima kasih.`;
            else if (car) return `Halo My Bali Rent Car, saya ingin memesan ${car}. Terima kasih.`;
            else return `Halo My Bali Rent Car, saya ingin memesan mobil/motor. Terima kasih.`;
        }
    } catch (error) {
        return error;
    }
}