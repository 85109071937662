import { Container, Typography, Button, Grid } from '@mui/material';
import { DatePicker, Form, Input } from 'antd';
import React from 'react';
import { fontFamily, primaryColor } from '../../Utils/Constants';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';
import withReactContent from 'sweetalert2-react-content';
import { FormattedMessage, useIntl } from 'react-intl';
import { DataContext } from '../../Context/DataContext';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { createTransactionCamp } from '../../Components/api/transactionCamp';
import { Navigate, useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const ubahHarga = (harga) => {
  if (harga === "0") {
    return "0";
  }
  return harga.substring(0, harga.length - 3) + '.' + harga.substring(harga.length - 3, harga.length);
}

const FormCheckout = (props) => {
  const { quantity, total, data } = props;
  const navigate = useNavigate();
  const { allState } = React.useContext(DataContext)
  const { rentDate, setRentDate, setOrderCamp } = allState;
  const intl = useIntl();
  const handleCheckout = () => {
    MySwal.fire({
      title: <strong>Berhasil di Pesan</strong>,
      html: <div> <p>Lihat Menu Bookingan Saya untuk Melihat Kode Booking Anda </p> </div>,
      timer: 5000,
      showConfirmButton: false,
      icon: 'success'
    })
  }

  useEffect(() => {
    const rentDate = JSON.parse(localStorage.getItem('rentDate'));
    console.log('rentDate', rentDate)
    if (rentDate) {
      setRentDate(rentDate);
    }
  }, [])

  const handleSubmit = (values) => {
    let notvalid = false;
    if (values.name === '' || values.email === '' || values.phoneNumber === '' || values.address === ''
      || values.name === undefined || values.email === undefined || values.phoneNumber === undefined || values.address === undefined) {
      notvalid = true;
    }
    if (notvalid) {
      MySwal.fire({
        title: <strong>Silahkan isi data anda terlebih dahulu</strong>,
        html: <i>Form tidak boleh kosong</i>,
        showConfirmButton: false,
        timer: 1500,
        icon: 'error'
      })
    } else {
      const payloadData = {
        ...values,
        pickupDate: rentDate[0],
        returnDate: rentDate[1],
        detail: data.map((item) => {
          return {
            id: item.id,
            quantity: item.quantity
          }
        })
      }
      createTransactionCamp(payloadData)
        .then((res) => {
          resetStateAndLocalStorage();
          MySwal.fire({
            title: <strong>Create Transaction Success</strong>,
            timer: 1500,
            showConfirmButton: false,
            icon: 'success'
          })
          navigate(`/order/${res.data.data.id}`);
        })
        .catch((err) => {
          MySwal.fire({
            title: <strong>Failed Create Transaction</strong>,
            html: <i>{err.response.data.message}</i>,
            showConfirmButton: false,
            timer: 1500,
            icon: 'error'
          })
        })
    }
  }

  const resetStateAndLocalStorage = () => {
    localStorage.removeItem('rentDate');
    localStorage.removeItem('cartcamp');
    setRentDate(null);
    setOrderCamp([]);
  }

  return (
    <div>
      <Container style={{ textAlign: 'start' }}>
        <Typography variant='h5' style={{ marginBottom: '30px' }}>
          Lengkapi Data Diri Anda
        </Typography>
        <Form
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item style={{ fontFamily: fontFamily }} name='pickUpDate' label={<FormattedMessage id='headerFormPickUp' />}>
                <DatePicker
                  disabled
                  value={rentDate ? dayjs(rentDate[0], 'YYYY-MM-DD') : null}
                  placeholder={rentDate ? rentDate[0] : intl.formatMessage({ id: 'hedaerFormPickUpPlaceHolder' })}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item style={{ fontFamily: fontFamily }} name='returnDate' label={<FormattedMessage id='headerFormDropOff' />}>
                <DatePicker
                  disabled
                  value={rentDate ? dayjs(rentDate[1], 'YYYY-MM-DD') : null}
                  placeholder={rentDate ? rentDate[1] : intl.formatMessage({ id: 'headerFormDropOffPlaceHolder' })}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Grid>
          </Grid>
          <Form.Item name={"name"} label="Nama Lengkap">
            <Input type="text" placeholder='Nama Lengkap' />
          </Form.Item>
          <Form.Item name={"address"} label="Alamat">
            <Input type="text" placeholder='Alamat' />
          </Form.Item>
          <Form.Item name={"city"} label="Kota">
            <Input type="text" placeholder='Kota' />
          </Form.Item>
          <Form.Item name={"province"} label="Provinsi">
            <Input type="text" placeholder='Provinsi' />
          </Form.Item>
          <Form.Item name={"postCode"} label="Kode Pos">
            <Input type="text" placeholder='Kode Pos' />
          </Form.Item>
          <Form.Item name={"phoneNumber"} label="Nomor Telepon">
            <Input type="text" placeholder='0812345678' />
          </Form.Item>
          <Form.Item name={"email"} label="Email">
            <Input type="text" placeholder='tes@gmail.com' />
          </Form.Item>
          <div style={{ marginTop: "2%", paddingTop: "2%", paddingBottom: "2%", backgroundColor: "white", textAlign: 'start' }}>
            <div style={{ display: "inline" }}>
              <Typography variant="h5" style={{ marginTop: "10px", display: "inline", marginRight: "10px" }}>
                Total ({quantity} produk)
              </Typography>
              <Typography variant="h5" style={{ marginTop: "10px", color: "#307606", display: "inline" }}>
                Rp {ubahHarga(String(total))}
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={() => window.location.href = '/all-items'} style={{ backgroundColor: primaryColor, color: "white", marginTop: "20px", width: '25%' }}>Kembali</Button>
              <Button type='submit' style={{ backgroundColor: primaryColor, color: "white", marginTop: "20px", width: '25%' }}>Pesan</Button>
            </div>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default FormCheckout;