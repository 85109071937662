import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { fontFamily, primaryColor, redColor } from '../../../Utils/Constants';
import { DataContext } from '../../../Context/DataContext';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { useContext } from 'react';
import { formatPrice } from '../../../Utils/functionUtils';
import ModalDetailCamp from '../../Modal/ModalDetailCamp';
import Swal from 'sweetalert2/dist/sweetalert2.all.js'
import withReactContent from 'sweetalert2-react-content'

const StyledCard = styled(Card)(({ theme }) => ({
  paddingTop: '1rem',
  cursor: 'pointer',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 0 rgba(0, 0, 0, 0.19)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 0 rgba(0, 0, 0, 0.19)',
    transform: 'scale(1.05)',
  },
}));

export default function CardItem(props) {
  const { id, name, priceDay, priceDayUp, image, stock, navigateToCheckout, priceFont } = props;
  const { allState } = useContext(DataContext)
  const { setOrderForm, rentDate } = allState;
  const [modalOpen, setModalOpen] = useState(false);
  const MySwal = withReactContent(Swal);
  const handleOrder = () => {
    setOrderForm(prevOrderForm => ({
      ...prevOrderForm,
      vehicle: name,
      idVehicle: id,
      image: image,
      price: priceDay,
    }));
    navigateToCheckout();
  }

  const handleShowModal = () => {
    setModalOpen(true);
  }

  const handleDetail = (id) => {
    if (rentDate) setModalOpen(true);
    else {
      MySwal.fire({
        title: <strong>Peringatan</strong>,
        html: <div> <p>Anda belum memilih tanggal sewa</p> </div>,
        icon: 'warning'
      })
    }
  }

  return (
    <div>
      <StyledCard sx={{ maxWidth: 375 }} key={id}>
        <CardMedia
          sx={{ height: 140 }}
          image={`images/camp/${image}`}
          title="camp item"
          onClick={() => handleDetail(id)}
          style={{ backgroundSize: 'contain' }}
        />
        <CardContent sx={{ height: 70, textAlign: 'center' }} onClick={() => handleDetail(id)} >
          <Typography sx={{ height: 60, textAlign: 'center' }} variant="h6" component="div" style={{ color: primaryColor, fontWeight: '700', fontFamily: 'Montserrat, sans-serif' }}>
            {name.toUpperCase()}
          </Typography>
          <Typography variant="body1" component="div" style={{ fontWeight: '700', fontFamily: 'Montserrat, sans-serif' }}>
            Stock : {stock}
          </Typography>
        </CardContent>
        <CardActions style={{ display: 'block', width: '100%', textAlign: 'center' }} onClick={() => handleShowModal()}>

          <Typography gutterBottom variant={priceFont} component="div" style={{ color: primaryColor, fontFamily: fontFamily }}>
            <s>{' Rp '}{formatPrice(priceDayUp)}</s> {' Rp '}{formatPrice(priceDay)} / hari
          </Typography>
        </CardActions>
        {
          stock > 0 ?
            <Button onClick={() => handleDetail(id)} size="small" variant="contained" style={{ backgroundColor: primaryColor, color: 'white', width: '100%', fontFamily: fontFamily }}>
              <FormattedMessage id="itemAddToCart" />
            </Button> : <Button disabled size="small" variant="contained" style={{ backgroundColor: redColor, color: 'white', width: '100%', fontFamily: fontFamily }}>
              Unavailable
            </Button>
        }
        <ModalDetailCamp
          id={id}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          image={image}
          name={name}
          priceDay={priceDay}
          stock={stock}
          handleOrderFromModal={handleOrder}
        />
      </StyledCard >
    </div>
  );
}