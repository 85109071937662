import React from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import { Container, Table, Typography, Hidden, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import FormCheckout from './FormCheckout';
import Paper from '@mui/material/Paper';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import { DataContext } from '../../Context/DataContext';
import { useEffect } from 'react';
import { getAllCamp } from '../../Components/api/camp';
import { useState } from 'react';

const dataCart = [
  {
    title: "Tenda 2 Orang",
    price: "Rp. 50.000",
    image: "images/item/tenda2.jpg",
    quantity: "1"
  },
  {
    title: "Tenda 4 Orang",
    price: "Rp. 100.000",
    image: "images/item/tenda4.jpg",
    quantity: "2"
  },
  {
    title: "Carrier 40L",
    price: "Rp. 50.000",
    image: "images/item/carrier.jpg",
    quantity: "3"
  },
]


const Cart = (props) => {
  const { allState } = React.useContext(DataContext)
  const { setOrderCamp, orderCamp } = allState;
  const [data, setData] = useState([]);

  useEffect(() => {
    const cartcamp = localStorage.getItem('cartcamp') ? JSON.parse(localStorage.getItem('cartcamp')) : [];
    setOrderCamp(cartcamp);
  }, [])

  useEffect(() => {
    getAllCamp().then((res) => {
      const cartcamp = localStorage.getItem('cartcamp') ? JSON.parse(localStorage.getItem('cartcamp')) : orderCamp;
      const data = cartcamp.map((cart) => {
        const camp = res.data.data.find((item) => item.id === cart.id);
        return {
          id: camp.id,
          title: camp.name,
          price: camp.pricePerDay,
          image: camp.image,
          stock: camp.stock,
          quantity: cart.quantity
        }
      })
      setData(data);
    })
  }, [])

  const handleMinusQuantity = (id) => {
    const cart = data.map((item) => {
      if (item.id === id) {
        if (item.quantity > 1) {
          return {
            ...item,
            quantity: item.quantity - 1
          }
        }
      }
      return item;
    })
    setData(cart);
  }

  const handlePlusQuantity = (id, stock) => {
    const cart = data.map((item) => {
      if (item.id === id) {
        if (item.quantity < stock) {
          return {
            ...item,
            quantity: item.quantity + 1
          }
        }
      }
      return item;
    })
    setData(cart);
  }


  return (
    <div>
      <>
        <div >
          <NavBar />
          <Container style={{ marginTop: "20px", paddingBottom: "2%", backgroundColor: "white", textAlign: 'start' }}>
            <Hidden smDown>
              <Typography style={{ color: "black", fontSize: "2rem", marginTop: "30px", marginBottom: '20px' }} >
                Keranjang Anda
              </Typography>
              <hr />
            </Hidden>
            <Hidden mdUp>
              <Typography style={{ color: "black", fontSize: "2rem", marginTop: "100px", marginBottom: '10px' }} >
                Keranjang Anda
              </Typography>
              <br />
            </Hidden>
            <TableContainer component={Paper}>
              <Table style={{ textAlign: 'center' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Produk</TableCell>
                    <TableCell align="center">Kuantitas</TableCell>
                    <TableCell align="center">Gambar</TableCell>
                    <TableCell align="center">Harga Satuan</TableCell>
                    <TableCell align="center">Total Harga</TableCell>
                    {/* <thead>
                  <tr>
                    <th>Produk</th>
                    <th>Kuantitas</th>
                    <th>Gambar</th>
                    <th>Harga Satuan</th>
                    <th>Total Harga</th>
                  </tr>
                </thead> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data !== null && data.map((cart, index) => {
                      return (
                        <TableRow
                            key={cart.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {cart.title}
                          </TableCell>
                          <TableCell align="center">
                            <Hidden smUp>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <button onClick={() => handleMinusQuantity(cart.id)} style={{ backgroundColor: primaryColor, border: '0', color: whiteColor, width: '50%' }}>-</button>
                                <button style={{ border: '0', width: '50%' }}>{cart.quantity}</button>
                                <button onClick={() => handlePlusQuantity(cart.id, cart.stock)} style={{ backgroundColor: primaryColor, border: '0', color: whiteColor, width: '50%' }}>+</button>
                              </div>
                            </Hidden>
                            <Hidden smDown>
                              <button onClick={() => handleMinusQuantity(cart.id)} style={{ backgroundColor: primaryColor, border: '0', color: whiteColor }}>-</button>
                              <button style={{ border: '0' }}>{cart.quantity}</button>
                              <button onClick={() => handlePlusQuantity(cart.id, cart.stock)} style={{ backgroundColor: primaryColor, border: '0', color: whiteColor }}>+</button>
                            </Hidden>
                          </TableCell>
                          <TableCell align="center"> <img alt='gambar' src={`images/camp/${cart.image}`} style={{ width: "100px", height: "100px" }} /></TableCell>
                          <TableCell align="center">{cart.price}</TableCell>
                          <TableCell align="center">{cart.quantity * cart.price}</TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Container>

          <FormCheckout
            data={data}
            quantity={data.map((item) => item.quantity).reduce((a, b) => a + b, 0)}
            total={data.map((item) => item.quantity * item.price).reduce((a, b) => a + b, 0)}
          />
        </div>
      </>
    </div>
  );
};

export default Cart;