import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import CopyRight from '../Copyright/Copyright';
import { styleContainer, styleTitle, styleSentences } from './FooterStyle';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, base_url_images } from '../../Utils/Constants';

const USEFUL_LINKS = [
    {
        id: 'navbarHome',
        endPoint: `${BASE_URL}`
    },
    {
        id: 'navbarAboutUs',
        endPoint: `${BASE_URL}/about-us`
    },
    {
        id: 'navbarContactUs',
        endPoint: `${BASE_URL}/contact-us`
    },
    {
        id: 'navbarBlog',
        endPoint: `${BASE_URL}/blog`
    }
]

const PRODUCT = [
    {
        id: 'navbarListProduct',
        endPoint: `/`
    },
    {
        id: 'navbarCart',
        endPoint: `/cart`
    }
]


const Footer = () => {
    const navigate = useNavigate();

    const handleLanding = () => {
        window.location.href = "./"
    }

    const navigateTo = (endPoint) => {
        if (endPoint.includes('https')) {
            window.location.href = endPoint;
        } else {
            navigate(endPoint);
        }
    }

    const navigateInstagram = () => {
        window.location.href = "https://www.instagram.com/mybalirentcar/"
    }

    return (
        <>
            <Container style={styleContainer} maxWidth>
                <div>
                    <Grid container >
                        <Grid item xs={12} md={3}>
                            <div style={{ marginTop: "5%" }}>
                                <img alt='gambar' sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} src={`${base_url_images}logo2.png`} width="50%" onClick={handleLanding} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container>
                                <Grid item xs={6} md={4}>
                                    <div style={{ textAlign: "left" }}>
                                        <Typography variant='body1' style={styleTitle}>
                                            <FormattedMessage id="footerUsefulLinks" />
                                        </Typography>
                                        <br />
                                        {
                                            USEFUL_LINKS.map((link, index) => {
                                                return (
                                                    <Typography key={`link-${index}`} variant='body1' style={styleSentences} onClick={() => navigateTo(link.endPoint)}>
                                                        <FormattedMessage id={link.id} />
                                                    </Typography>
                                                )
                                            })
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <div style={{ textAlign: "left" }}>
                                        <Typography variant='body1' style={styleTitle}>
                                            <FormattedMessage id="footerProduct" />
                                        </Typography>
                                        <br />
                                        {
                                            PRODUCT.map((link, index) => {
                                                return (
                                                    <Typography key={`produk-${index}`} variant='body1' style={styleSentences} onClick={() => navigateTo(link.endPoint)}>
                                                        <FormattedMessage id={link.id} />
                                                    </Typography>
                                                )
                                            })
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <div style={{ textAlign: "left" }}>
                                        <Typography variant='body1' style={styleTitle}>
                                            <FormattedMessage id="socialMedia" />
                                        </Typography>
                                        <br />
                                        <Typography variant='body1' style={styleSentences} onClick={navigateInstagram}>
                                            <FormattedMessage id="instagram" />
                                        </Typography>
                                        <Typography variant='body1' style={styleSentences}>
                                            <FormattedMessage id="tiktok" />
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={0} md={0.5}>

                        </Grid>
                        <Grid item xs={11}>
                            <CopyRight />
                        </Grid>
                    </Grid>
                </div>
            </Container >
        </>
    );
};

export default Footer;